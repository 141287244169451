import Grid from '@mui/material/Grid';
import React from 'react';
import { Tooltip } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import ActivityList from '../components/ActivityList';
import Occupation from '../components/elements/Occupation';
import _ from 'lodash';
import { useActivity } from '../services/activity';
import { flattenPages } from '../util/query';
import { useTrustConfig } from '../services/config';

const abilities = {
  answer: {
    title: 'Share Opinion',
    description:
      'You can record your opinion on Inquiry questions (no / unlikely / maybe / likely / yes)',
  },
  theoryComment: {
    title: 'Inquiry Comments',
    description: 'You can post comments on inquiries which notify the author',
  },
  awardSways: {
    title: 'Award Sways',
    description:
      'Changes to your opinion (no / unlikely / maybe / likely / yes) will award Sways to promoted Evidence/Rebuttals',
  },
  promote: {
    title: 'Ranking I',
    description: 'Your promotes/demotes now influence the rank of evidence',
  },
  initiateConversation: {
    title: 'Send messages',
    description: 'You can send messages to other users',
  },
  createTheory: {
    title: 'Create Inquiries',
    description: 'You can create new Inquiries',
  },
  subscribeToUser: {
    title: 'Subscribe to users',
    description: 'You can subscribe to users to receive updates when they post content',
  },
  uploadImage: {
    title: 'Upload images',
    description: 'You can upload images for your profile, evidence, and your Inquiries',
  },
  invite: {
    title: 'Invite',
    description:
      'You can invite new members by their email, granting them bonus 📜 when they sign up',
  },
  evidenceComment: {
    title: 'Evidence Comments',
    description: 'You can post comments on evidence which notify the author',
  },
  copyPaste: {
    title: 'Copy/paste Evidence/Rebuttals',
    description: 'You can copy & paste evidence/rebuttals to quickly place them where needed',
  },
  clusterEvidence: {
    title: 'Mark Duplicate Evidence',
    description:
      'You can mark evidence as duplicate of other evidence thereby hiding the lower-ranked one',
  },
  showArchived: {
    title: 'Show Withdrawn',
    description: 'You can view all withdrawn Evidence and Rebuttals',
  },
  showDrafts: {
    title: 'Show Drafts',
    description: 'You can view all Evidence and Rebuttal drafts',
  },
  doublePromote: {
    title: 'Ranking II',
    description: 'You can now promote/demote twice',
  },
  changeUsername: {
    title: 'Change username',
    description: 'You can change your @username (not yet implemented)',
  },
  evince: {
    title: 'Submit Evidence',
    description: 'You can submit new evidence to an inquiry',
  },
  editEvidence: {
    title: 'Edit Arguments',
    description: "You may edit others' Evidence and Rebuttals to fix errors",
  },
  addAuthor: {
    title: 'Add Author',
    description:
      'You may associate usernames or emails as the authors of any cited Evidence/Rebuttal.',
  },
  maxThrottle: {
    title: 'Maximum throttle',
    description: 'You have significantly higher limits for several types of activities',
  },
  triplePromote: {
    title: 'Rank III',
    description: 'Your single-ranks count as 2 and your double-ranks count as 3',
  },
  quadruplePromote: {
    title: 'Rank IV',
    description: 'Your single-ranks count as 2 and your double ranks count as 4',
  },
};

const UserTrust = ({ me }) => {
  const trustConfig = useTrustConfig().data?.data;
  const trustActivityTypes = trustConfig ? Object.keys(trustConfig.activityTrustEarns) : [];
  const activityTypes = _.without(trustActivityTypes, ['evidence-rank-log', 'theory-rank-log']);
  const trustActivityQuery = useActivity({
    idOrUsername: me?.id,
    types: activityTypes,
    enabled: !!me?.id && !!trustActivityTypes,
  });
  const trustActivities = flattenPages(trustActivityQuery.data?.pages);

  const trustLevels = trustConfig?.trustLevels;
  const trustTableData =
    trustLevels &&
    Object.keys(trustLevels).map(level => ({
      title: level,
      occupation: <Occupation user={{ trustLevel: level }} noTooltip className='text-unselected' />,
      trustNeeded: trustLevels[level].trustNeeded,
      achieved: me && me.trustLevel >= level,
      abilities: Object.keys(trustLevels[level])
        .filter(ability => ability !== 'trustNeeded')
        .filter(ability => abilities[ability])
        .map(ability => abilities[ability]),
    }));

  return (
    <div>
      {trustTableData && (
        <Grid container rowSpacing={2} mx={4}>
          <Grid item xs={6}>
            <p className='p-4 font-bold'>Levels</p>
          </Grid>
          <Grid item xs={6}>
            <p className='p-4 font-bold'>Abilities</p>
          </Grid>
          {trustTableData.map((level, i) => (
            <React.Fragment key={i}>
              <Grid item xs={6}>
                <div className='flex items-center space-x-4 pr-4'>
                  <Tooltip
                    placement='right'
                    title={level.trustNeeded ? level.trustNeeded + ' 📜' : ''}
                  >
                    <div className='flex w-full text-base items-center space-x-2'>
                      <p className='font-bold'>{level.title}:</p>
                      {level.occupation}
                    </div>
                  </Tooltip>
                  {level.achieved && <CheckIcon />}
                </div>
              </Grid>
              <Grid item container xs={6}>
                {level.abilities.length ? (
                  level.abilities.map((ability, j) => (
                    <Grid item key={j} xs={12}>
                      <Tooltip placement='left' title={ability?.description}>
                        <p className='mt-1 text-sm'>{ability?.title}</p>
                      </Tooltip>
                    </Grid>
                  ))
                ) : (
                  <Grid item xs={12}>
                    <p className='mt-1 text-sm'>-------------------</p>
                  </Grid>
                )}
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      )}

      <div className='m-6'>
        <ActivityList activities={trustActivities} showTrust />
      </div>
    </div>
  );
};

export default UserTrust;
