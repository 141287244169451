import { useSearchParams } from 'react-router-dom';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { sameId } from '../../util/id';
import CommentResponseEditor from './CommentResponseEditor';
import UiContext from '../../state/UiContext';
import { useDeleteComment, useMarkRead, useResolve } from '../../services/comments';
import classnames from 'classnames';
import AuthContext from '../../state/AuthContext';
import UsernameCreatedAt from '../elements/UsernameCreatedAt';
import Username from '../elements/Username';
import CommentResponse from './CommentResponse';
import { isElementInScrollView } from '../../util/dom';
import UserAvatar from '../elements/UserAvatar';
import CommentBody from './CommentBody';

const Comment = ({ subject, comment, setUnsavedResponse, previewResponse }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { toastError } = useContext(UiContext);
  const [viewAllResponses, setViewAllResponses] = useState(false);
  const [clamped, setClamped] = useState(true);
  const { me, isAdmin } = useContext(AuthContext);
  const resolve = useResolve();
  const markRead = useMarkRead();
  const [highlighted, setHighlighted] = useState(false);
  const ref = useRef();
  const [showToolbar, setShowToolbar] = useState(false);
  const deleteComment = useDeleteComment();
  const [confirmingDelete, setConfirmingDelete] = useState(false);

  const [numberToShow, numberToHide, hidingResponses] = useMemo(() => {
    const numberToShow = viewAllResponses ? comment.responses.length : previewResponse ? 1 : 0;
    const numberToHide = comment.responses.length - numberToShow;
    const hidingResponses = numberToHide > 0;
    return [numberToShow, numberToHide, hidingResponses];
  }, [viewAllResponses, comment, previewResponse]);

  const onConfirmDelete = useCallback(() => {
    setConfirmingDelete(false);
    deleteComment.mutate(
      { subjectId: subject.id, commentId: comment.id },
      {
        onError: data => toastError(data.message),
      },
    );
  }, [deleteComment, subject, comment, toastError]);

  const onClickReplies = useCallback(() => {
    if (comment && subject && !comment.read)
      markRead.mutate({ subjectId: subject.id, id: comment.id });
    setViewAllResponses(true);
    setClamped(false);
  }, [comment, markRead, subject]);

  const onClickText = useCallback(() => {
    if (subject && comment && !hidingResponses && !comment.read)
      markRead.mutate({ subjectId: subject.id, id: comment.id });
    setShowToolbar(t => !t);
  }, [subject, comment, markRead, hidingResponses]);

  useEffect(() => {
    let timeout;

    if (ref.current && comment.id === searchParams.get('showComment')) {
      timeout = setTimeout(() => {
        ref.current.scrollIntoView();
        setViewAllResponses(true);
        searchParams.delete('showComment');
        searchParams.delete('showResponse');
        setSearchParams(searchParams, { replace: true });
        setHighlighted(comment.responses.findIndex(r => r.id === searchParams.get('showResponse')));
      }, 50);
    }
    return () => clearTimeout(timeout);
  }, [ref, comment, searchParams, setSearchParams, markRead, subject?.id]);

  const isAuthor = sameId(me, comment.author);
  const isCommenter = sameId(me, comment.user);
  const resolved =
    (isAuthor && comment.authorResolved) ||
    (isCommenter && comment.commenterResolved) ||
    (comment.authorResolved && comment.commenterResolved);

  function onResolve(e) {
    e.stopPropagation();
    resolve.mutate(
      { subjectId: subject?.id, id: comment.id },
      {
        onError: data => toastError(data.message),
      },
    );
  }

  useEffect(() => {
    const onScroll = () => {
      if (
        !comment.read &&
        !hidingResponses &&
        isElementInScrollView(ref.current) &&
        !markRead.isLoading
      )
        markRead.mutate({ subjectId: subject?.id, id: comment?.id });
    };
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, [comment, markRead, subject, hidingResponses]);

  const resolvedBy = [
    ...(comment.authorResolved ? [subject?.user?.username] : []),
    ...(comment.commenterResolved ? [comment.user?.username] : []),
  ];

  return (
    <div
      ref={ref}
      className={classnames('w-full flex flex-col p-3 shadow-md', {
        'bg-blue-100': highlighted === -1,
      })}
    >
      <div className='flex flex-row space-x-3'>
        <UserAvatar user={comment.user} className='size-8 rounded-full' />
        <div className='flex flex-col space-y-2'>
          <UsernameCreatedAt
            username={comment.user.username}
            createdAt={comment.createdAt}
            compact
          />
          <div className='mt-2'>
            <div
              className={classnames(
                'my-auto',
                { 'mb-3': clamped },
                comment.read ? 'text-unselected' : 'text-regular',
              )}
            >
              <CommentBody
                subject={comment}
                clamped={clamped}
                onClick={onClickText}
                onClickReadMore={() => setClamped(false)}
              />
            </div>
          </div>
          {showToolbar &&
            (isAdmin || isCommenter) &&
            (confirmingDelete ? (
              <div className='flex flex-row justify-center space-x-12'>
                <button onClick={onConfirmDelete}>Confirm</button>
                <button onClick={() => setConfirmingDelete(false)}>Cancel</button>
              </div>
            ) : (
              <button
                className='border p-3 rounded-full m-auto text-unselected'
                disabled={deleteComment.isLoading}
                onClick={() => setConfirmingDelete(true)}
              >
                {deleteComment.isLoading ? 'Deleting...' : 'Delete'}
              </button>
            ))}
        </div>
      </div>

      <div className='pl-10 flex flex-col'>
        {comment.responses?.slice(0, numberToShow).map((response, i) => (
          <div key={response.id} className={classnames('mt-3', { 'mb-3': clamped })}>
            <CommentResponse
              subject={subject}
              response={response}
              highlighted={i === highlighted}
            />
          </div>
        ))}

        <div className='flex flex-row-reverse justify-between'>
          {!hidingResponses && (isAuthor || isCommenter) && (
            <button
              className={
                'self-end px-3 text-xs border-2 rounded ' +
                (!resolved ? 'text-secondary' : 'text-gray-500')
              }
              onClick={onResolve}
            >
              {resolved ? 'Resolved' : 'Resolve'}
            </button>
          )}

          {!hidingResponses && resolvedBy.length === 1 && (
            <div>
              <Username username={resolvedBy[0]} /> marked as resolved
            </div>
          )}

          {!hidingResponses && resolvedBy.length === 2 && (
            <div>
              Resolved by <Username username={resolvedBy[0]} /> and{' '}
              <Username username={resolvedBy[1]} />
            </div>
          )}
        </div>

        {hidingResponses && (
          <button
            onClick={onClickReplies}
            className={classnames(
              'text-left ml-7',
              comment.read ? 'text-unselected' : 'text-highlighted',
            )}
          >
            ---{' '}
            {numberToHide === 1
              ? previewResponse
                ? '1 more reply'
                : '1 reply'
              : previewResponse
              ? numberToHide + ' more replies'
              : numberToHide + ' replies'}{' '}
            ---
          </button>
        )}

        <div className='mt-4 flex flex-row space-x-3'>
          <UserAvatar user={me} className={'size-6 rounded-full'} />

          <CommentResponseEditor
            subject={subject}
            comment={comment}
            setUnsavedResponse={setUnsavedResponse}
            onSuccess={() => {
              setViewAllResponses(true);
              setClamped(false);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Comment;
