import { fetchClient } from 'services/api';
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from 'react-query';
import { staleTimes } from './query-settings';
import id from '../util/id';
import _ from 'lodash';
import { keys as clusterKeys } from './clusters';

const apiClient = fetchClient();

export const keys = {
  one: idOrSlug => ['evidence', idOrSlug],
  allOnes: () => ['evidence'],
  children: obj => ['evidences', obj],
  user: user => ['evidences', { user }],
  allChildren: () => ['evidences'],
  latest: (user, status, parent, swayed, authorless) => [
    'latestEvidence',
    { user, status, parent, swayed, authorless },
  ],
  allLatest: () => ['latestEvidence'],
  revisions: id => ['evidenceRevisions', id],
};

function updateCacheWithRecord(queryClient, evidence, data) {
  const update = (oldData, newData) => {
    const depopulatedKeys = Object.keys(newData).filter(
      k => oldData[k]?.id && oldData[k]?.id === newData[k],
    );
    return {
      ...oldData,
      ..._.omit(newData, depopulatedKeys),
    }; // avoid overwriting joins
  };

  // if this evidence was fetched specifically, update that cache
  if (queryClient.getQueryData(keys.one(evidence.id)))
    queryClient.setQueryData(keys.one(evidence.id), oldEvidence => {
      return { data: update(oldEvidence.data, data) };
    });

  // if this evidence is in any list, update it (could make this more generic)
  ['evidences', 'latestEvidence'].forEach(filter => {
    queryClient.getQueriesData([filter]).forEach(([k, d]) => {
      if (d)
        queryClient.setQueryData(k, oldEvidences => {
          return {
            ...oldEvidences,
            ...(oldEvidences.data
              ? { data: oldEvidences.data.map(e => (e.id === evidence.id ? update(e, data) : e)) }
              : undefined),
            ...(oldEvidences.pages
              ? {
                  pages: oldEvidences.pages.map(page => ({
                    ...page,
                    ...(page.data
                      ? { data: page.data.map(e => (e.id === evidence.id ? update(e, data) : e)) }
                      : undefined),
                  })),
                }
              : undefined),
          };
        });
    });
  });
}

const getEvidence = async idOrSlug => apiClient.get('/evidence/' + idOrSlug).then(res => res.data);

export const useEvidence = idOrSlug => {
  const queryClient = useQueryClient();
  return useQuery(keys.one(idOrSlug), () => getEvidence(idOrSlug), {
    onSuccess: data =>
      // cache by both id and slug
      ['id', 'slug'].forEach(
        f =>
          data?.data?.[f] &&
          !queryClient.getQueryData(keys.one(data.data[f])) &&
          queryClient.setQueryData(keys.one(data.data[f]), data),
      ),
    enabled: !!idOrSlug,
    staleTime: staleTimes.medium,
  });
};

const getAllEvidences = async ({
  user,
  page,
  status,
  swayed,
  parent,
  sortBy,
  authorless,
  perPage,
}) => {
  const params = new URLSearchParams(
    _.pickBy(
      { perPage, page, user, status, parent, swayed, sortBy, authorless },
      p => p !== undefined,
    ),
  ).toString();
  return apiClient.get('/evidence/?' + params).then(res => res.data);
};

export const useAllEvidences = ({ user, status, parent, swayed, sortBy, authorless, perPage }) =>
  useInfiniteQuery(
    keys.latest(user, status, parent, swayed, authorless),
    ({ pageParam }) =>
      getAllEvidences({
        page: pageParam,
        user,
        swayed,
        status,
        parent,
        sortBy,
        authorless,
        perPage,
      }),
    {
      getNextPageParam: lastPage => lastPage.meta.nextPage || undefined,
      staleTime: staleTimes.long,
    },
  );

const getTheoryEvidence = async ({ status, theoryId, parentId, isFor, page, perPage }) => {
  const params = new URLSearchParams(
    _.pickBy({ parent: parentId, status, for: isFor, perPage, page }, f => f !== undefined),
  ).toString();
  return apiClient.get('/theory-evidence/' + theoryId + '?' + params).then(res => res.data);
};

export const useTheoryEvidence = function (
  { user, theoryId, parentId, isFor, perPage, status },
  options = {},
) {
  return useInfiniteQuery(
    keys.children({ theoryId, parentId, isFor, status }),
    ({ pageParam }) =>
      getTheoryEvidence({ theoryId, parentId, status, isFor, page: pageParam, perPage }),
    {
      staleTime: staleTimes.medium,
      enabled: !!theoryId,
      ...options,
      getNextPageParam: lastPage => lastPage?.meta?.nextPage || undefined,
    },
  );
};

const submitEvidence = async evidence => await apiClient.post('/evidence', evidence);

export const useSubmitEvidence = function () {
  const queryClient = useQueryClient();
  return useMutation(
    ({ title, body, link, citation, theoryId, parent, isFor, clusterWith }) =>
      submitEvidence({
        title,
        body,
        link,
        citation,
        theoryId,
        parent,
        for: isFor,
        clusterWith,
      }).then(res => res.data),
    {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries(
          keys.children({
            theoryId: variables.theoryId,
            parentId: id(variables.parent) || null,
            isFor: variables.isFor,
          }),
        );
        queryClient.invalidateQueries(keys.allLatest());
        if (variables.clusterWith)
          queryClient.invalidateQueries(clusterKeys.theory(variables.theoryId));
      },
    },
  );
};

const startUpdateEvidence = async id => await apiClient.patch('evidence/' + id + '/start');

export const useStartUpdateEvidence = function () {
  const queryClient = useQueryClient();
  return useMutation(evidence => startUpdateEvidence(evidence.id).then(res => res.data), {
    onSuccess: (data, evidence) => updateCacheWithRecord(queryClient, evidence, data.data),
  });
};

const updateEvidence = async ({ id, title, body, link, citation }) =>
  await apiClient.patch('evidence/' + id, { title, body, link, citation });

export const useUpdateEvidence = function () {
  const queryClient = useQueryClient();
  return useMutation(
    ({ evidence, ...content }) =>
      updateEvidence({ id: evidence.id, ...content }).then(res => res.data),
    {
      onSuccess: (data, variables) => {
        updateCacheWithRecord(queryClient, variables.evidence, data.data);
        queryClient.invalidateQueries(keys.revisions(variables.evidence.id));
      },
    },
  );
};

// Created to look through cached data for parent.  Not needed right now as long as the mutation user sends parent data.
// but might come in handy at some point
/*function findEvidenceParent(queryClient, id) {
  // look through all the cached evidence data to find the parent id
  queryClient.getQueriesData(['evidence']).reduce((prev, curr) => prev ? prev :
    curr.reduce(data => (prev, curr) => (prev || curr.data.id !== id) ? prev : curr.data.parent), null)
}*/

const markEvidenceRead = async id =>
  await apiClient.post('/evidence/' + id + '/read').then(res => res.data);

export const useMarkEvidenceRead = () => {
  const queryClient = useQueryClient();
  return useMutation(({ evidence }) => markEvidenceRead(evidence.id), {
    onSuccess: (data, variables) =>
      updateCacheWithRecord(queryClient, variables.evidence, { read: true }),
  });
};

const rankEvidence = async (id, { rank }) =>
  await apiClient.post('/evidence/' + id + '/rank', { rank }).then(res => res.data);

export const useRankEvidence = () => {
  const queryClient = useQueryClient();
  return useMutation(({ evidence, rank }) => rankEvidence(evidence.id, { rank: rank }), {
    onSuccess: (data, variables) =>
      updateCacheWithRecord(queryClient, variables.evidence, {
        userRank: variables.rank,
        ...data.data,
      }),
  });
};

const deleteEvidence = async id => await apiClient.delete('/evidence/' + id);

export const useDeleteEvidence = () => {
  const queryClient = useQueryClient();
  return useMutation(evidence => deleteEvidence(evidence.id).then(res => res.data), {
    onSuccess: (data, variables) => {
      const evidenceKey = keys.one(variables.id);
      if (queryClient.getQueryData(evidenceKey)) queryClient.removeQueries(evidenceKey);

      queryClient.invalidateQueries(keys.allChildren());
      queryClient.invalidateQueries(keys.allLatest());
    },
  });
};

const unpublishEvidence = async (id, reason) =>
  await apiClient.patch('/evidence/' + id + '/unpublish/', { reason });

export const useUnpublishEvidence = () => {
  const queryClient = useQueryClient();
  return useMutation(
    evidence => unpublishEvidence(evidence.id, evidence.archiveReason).then(res => res.data),
    {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries(keys.allChildren());
        queryClient.invalidateQueries(keys.allLatest());
        queryClient.invalidateQueries(keys.one(variables.id));
      },
    },
  );
};

const publishEvidence = async id =>
  await apiClient.post('/evidence/' + id + '/publish').then(res => res.data);

export const usePublishEvidence = () => {
  const queryClient = useQueryClient();
  return useMutation(evidence => publishEvidence(evidence.id), {
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(keys.allChildren());
      queryClient.invalidateQueries(keys.allLatest());
      queryClient.invalidateQueries(keys.one(variables.id));
      if (variables.slug) queryClient.invalidateQueries(keys.one(variables.slug));
    },
  });
};

const getRevisions = async id =>
  await apiClient.get('/evidence/revisions/' + id).then(res => res.data);

export const useGetRevisions = (id, options) =>
  useQuery(keys.revisions(id), () => getRevisions(id), options);

const addAuthor = async (evidenceId, author, displayName) =>
  await apiClient
    .post('/evidence/' + evidenceId + '/author', { author, displayName })
    .then(res => res.data);

export const useAddAuthor = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ evidence, author, displayName }) => addAuthor(evidence.id, author, displayName),
    {
      onSuccess: (data, variables) =>
        updateCacheWithRecord(queryClient, variables.evidence, { authors: data.data }),
    },
  );
};

const claim = async evidenceId =>
  await apiClient.patch('/evidence/' + evidenceId + '/claim').then(res => res.data);

export const useClaim = () => {
  const queryClient = useQueryClient();
  return useMutation(({ evidence }) => claim(evidence.id), {
    onSuccess: (data, variables) =>
      updateCacheWithRecord(queryClient, variables.evidence, data.data),
  });
};
