import { fetchClient } from 'services/api';
import { staleTimes } from './query-settings';
import { useQuery, useMutation, useQueryClient, useInfiniteQuery } from 'react-query';

import { keys as evidenceKeys } from './evidence';
import { userQueryKeys } from './users';
import { useEffect } from 'react';
import _ from 'lodash';

const apiClient = fetchClient();

const keys = {
  one: idOrSlug => ['theory', idOrSlug],
  list: filters => ['theories', filters],
  allLists: ['theories'],
  allOnes: 'theory',
  revisions: id => ['theory-revisions', id],
};

const getTheories = async ({ tagSlug, user, status, page }) => {
  const params = new URLSearchParams(
    _.pickBy({ page, user, status, tagSlug }, p => p !== undefined),
  ).toString();
  return apiClient.get('/theory?' + params).then(res => res.data);
};

export const useTheories = ({ tagSlug, user, status }, options = {}) =>
  useInfiniteQuery(
    keys.list({ tagSlug, user, status }),
    ({ pageParam }) => getTheories({ tagSlug, user, status, page: pageParam }),
    {
      getNextPageParam: lastPage => lastPage.meta.nextPage || undefined,
      //staleTime: staleTimes.long,
      ...options,
    },
  );

const getAdminTheories = ({ status, page }) => {
  const params = new URLSearchParams(_.pickBy({ page, status }, p => p !== undefined)).toString();
  return apiClient.get('/admin/theory?' + params).then(res => res.data);
};
export const useAdminTheories = ({ status }) =>
  useInfiniteQuery(
    keys.list({ admin: true, status }),
    ({ pageParam }) => getAdminTheories({ status, page: pageParam }),
    {
      getNextPageParam: lastPage => lastPage.meta.nextPage || undefined,
      staleTime: staleTimes.medium,
    },
  );

const getTheory = async idOrSlug => apiClient.get('/theory/' + idOrSlug).then(res => res.data);

export const useGetTheory = idOrSlug => {
  const queryClient = useQueryClient();
  return useQuery(keys.one(idOrSlug), () => getTheory(idOrSlug), {
    staleTime: staleTimes.medium,
    enabled: !!idOrSlug,
    onSuccess: data =>
      // cache by both id and slug
      ['id', 'slug'].forEach(
        f =>
          data?.data?.[f] &&
          !queryClient.getQueryData(keys.one(data.data[f])) &&
          queryClient.setQueryData(keys.one(data.data[f]), data),
      ),
  });
};

const updateLikelihood = async (theoryId, likelihood, notifyUsername) =>
  await apiClient.post(
    '/theory/' + theoryId + '/likelihood' + (notifyUsername ? '?notify=' + notifyUsername : ''),
    { likelihood },
  );

export const useUpdateLikelihood = function () {
  const queryClient = useQueryClient();
  return useMutation(
    ({ theoryId, likelihood, notifyUsername }) =>
      updateLikelihood(theoryId, likelihood, notifyUsername).then(res => res.data),
    {
      onSuccess: (data, variables) => {
        const key = keys.one(variables.theoryId);
        if (queryClient.getQueryData(key))
          queryClient.setQueryData(key, oldTheory => {
            return {
              ...oldTheory,
              data: {
                ...oldTheory.data,
                userLikelihood: {
                  ...oldTheory.userLikelihood,
                  likelihood: variables.likelihood,
                  updatedAt: new Date(),
                },
              },
            };
          });

        queryClient.setQueriesData(evidenceKeys.allOnes(), oldEvidence =>
          oldEvidence
            ? {
                ...oldEvidence,
                data: {
                  ...oldEvidence?.data,
                  theory: {
                    ...oldEvidence.data?.theory,
                    userLikelihood: {
                      ...oldEvidence.data?.theory?.userLikelihood,
                      likelihood: variables.likelihood,
                      updatedAt: new Date(),
                    },
                  },
                },
              }
            : undefined,
        );

        if (queryClient.getQueryData(userQueryKeys.me))
          queryClient.setQueryData(userQueryKeys.me, oldMe => ({
            ...oldMe,
            data: { ...oldMe.data, dissent: data.data.dissent, certainty: data.data.certainty },
          }));

        if (queryClient.getQueryData('likelihoods'))
          queryClient.setQueryData('likelihoods', oldHoods => ({
            ...oldHoods,
            data: oldHoods.data
              .map(ol => ({
                ...ol,
                likelihood:
                  ol.theory.id === variables.theoryId ? variables.likelihood : ol.likelihood,
              }))
              .concat(
                oldHoods.data.some(l => l.theory.id === variables.theoryId)
                  ? []
                  : { likelihood: variables.likelihood, theory: { id: variables.theoryId } },
              ),
          }));

        queryClient.invalidateQueries(['activity']);
      },
    },
  );
};

const startUpdateTheory = async id => await apiClient.patch('theory/' + id + '/start');

export const useStartUpdateTheory = function () {
  const queryClient = useQueryClient();
  return useMutation(pt => startUpdateTheory(pt.id).then(res => res.data), {
    onSuccess: () =>
      queryClient.invalidateQueries(keys.allLists) && queryClient.invalidateQueries(keys.allOnes),
  });
};

const updateTheory = async theory => {
  const formData = new FormData();
  ['title', 'body', 'image', 'consensus', 'sd'].forEach(
    field => theory[field] !== undefined && formData.append(field, theory[field]),
  );
  if (theory.tags) formData.append('tags', JSON.stringify(theory.tags));

  return await apiClient
    .patch('/theory/' + theory.id, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
    .then(res => res.data);
};

export const useUpdateTheory = () => {
  const queryClient = useQueryClient();
  return useMutation(theory => updateTheory(theory), {
    onSuccess: () => {
      queryClient.invalidateQueries('theories');
      queryClient.invalidateQueries('theory');
    },
  });
};

const getRevisions = async id =>
  await apiClient.get('/theory/revisions/' + id).then(res => res.data);

export const useGetRevisions = (id, options) =>
  useQuery(keys.revisions(id), () => getRevisions(id), options);

const updateTags = async (id, tags) =>
  await apiClient.patch('/theory/' + id + '/tags/', { tags }).then(res => res.data);

export const useUpdateTags = () => {
  const queryClient = useQueryClient();
  return useMutation(({ theory, tags }) => updateTags(theory.id, tags), {
    onSuccess: () => {
      queryClient.invalidateQueries('theories');
      queryClient.invalidateQueries('theory');
    },
  });
};

const publishTheory = async id =>
  await apiClient.post('/theory/' + id + '/publish').then(res => res.data);

export const usePublishTheory = function () {
  const queryClient = useQueryClient();
  return useMutation(id => publishTheory(id), {
    onSuccess: data => {
      queryClient.invalidateQueries(keys.allLists);
      queryClient.invalidateQueries(keys.one({ id: data.data.id }));
    },
  });
};

const unpublishTheory = async id => await apiClient.patch('/theory/' + id + '/unpublish');

export const useUnpublishTheory = function () {
  const queryClient = useQueryClient();
  return useMutation(id => unpublishTheory(id).then(res => res.data), {
    onSuccess: () => queryClient.invalidateQueries(keys.allLists),
  });
};

const unarchiveTheory = async id => await apiClient.post('/theory/' + id + '/unarchive');

export const useUnarchiveTheory = function () {
  const queryClient = useQueryClient();
  return useMutation(id => unarchiveTheory(id).then(res => res.data), {
    onSuccess: () => queryClient.invalidateQueries('theories'),
  });
};

export const markTheoryRead = async id =>
  await apiClient.post('/theory/' + id + '/read').then(res => res.data);

const getLikelihoodStats = async () =>
  await apiClient.get('/theory/likelihood-stats').then(res => res.data);

export const useLikelihoodStats = () => {
  const queryClient = useQueryClient();
  const key = 'likelihood-stats';
  useEffect(() => {
    const i = setInterval(() => {
      queryClient.invalidateQueries(key);
    }, 20000);
    return () => clearInterval(i);
  }, [queryClient]);
  return useQuery(key, getLikelihoodStats, { staleTime: staleTimes.blip });
};

const createTheory = async theory => {
  const formData = new FormData();
  ['title', 'body', 'image'].forEach(
    field => theory[field] && formData.append(field, theory[field]),
  );
  if (theory.tags) formData.append('tags', JSON.stringify(theory.tags));

  return await apiClient
    .post('/theory/', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
    .then(res => res.data);
};

export const useCreateTheory = function () {
  const queryClient = useQueryClient();
  return useMutation(createTheory, {
    onSuccess: () => {
      queryClient.invalidateQueries(keys.allLists);
      queryClient.invalidateQueries(['throttle']);
    },
  });
};

const rankTheory = async (theoryId, rank) =>
  await apiClient.post('/theory/' + theoryId + '/rank', rank).then(res => res.data);

export const useRankTheory = function () {
  const queryClient = useQueryClient();
  return useMutation(({ theory, rank }) => rankTheory(theory.id, { rank }), {
    onSuccess: (data, variables) => {
      // update theories lists
      queryClient.invalidateQueries(keys.allLists);

      // update this theory
      if (queryClient.getQueryData(keys.one(variables.theory.id)))
        queryClient.setQueryData(keys.one(variables.theory.id), oldTheory => {
          return {
            ...oldTheory,
            data: {
              ...oldTheory.data,
              ...data.data,
              userRank: variables.rank,
            },
          };
        });
    },
  });
};
