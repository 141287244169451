import { useCallback, useEffect, useRef, useState } from 'react';
import { useUserSearch } from '../../services/search';
import { validateUsername } from '../../services/validators';
import classnames from 'classnames';
import Popper from '@mui/material/Popper';
import ui from '../../config/ui';
import UserAvatar from '../elements/UserAvatar';
import { useNavigate } from 'react-router-dom';
import { useHasPrivilege } from '../../services/payments';
import { useCheckThrottle } from '../../services/users';

const CommentBodyTextarea = ({ placeholder, body, expanded, onChange, onClick }) => {
  const ref = useRef();
  const [searchString, setSearchString] = useState('');
  const searchResults = useUserSearch({ searchString }).data?.data;
  const [selected, setSelected] = useState(-1);
  const hasCommentPrivilege = useHasPrivilege('comment');
  const throttle = useCheckThrottle('comment').data?.data;
  const navigate = useNavigate();
  const [showMembershipPrompt, setShowMembershipPrompt] = useState(false);

  const _onClick = () => {
    if (!hasCommentPrivilege && throttle?.atLimit) setShowMembershipPrompt(true);
    else onClick();
  };

  useEffect(() => {
    let timer = setTimeout(() => {
      setSearchString('');
      setSelected(-1);
      const lastAt = body.substring(0, ref.current?.selectionStart).lastIndexOf('@');
      if (~lastAt) {
        const ss = body.substring(lastAt + 1, ref.current.selectionStart);
        if (!validateUsername(ss)) {
          setSearchString(ss);
        }
      }
    }, 800);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [body, ref]);

  const onSelectUsername = useCallback(
    username => () => {
      const lastAt = body.substring(0, ref.current.selectionStart).lastIndexOf('@');
      const usernameMd = `[@${username}](/user/${username})`;
      const newBody =
        body.substring(0, lastAt) + usernameMd + body.substring(ref.current.selectionStart);
      setSelected(-1);
      setSearchString('');
      onChange(newBody);
      ref.current.focus();
    },
    [ref, body, onChange],
  );

  useEffect(() => {
    function onKey(e) {
      if (searchResults) {
        if (e.key === 'ArrowUp' && selected > 0) {
          e.preventDefault();
          setSelected(selected - 1);
        }

        if (e.key === 'ArrowDown' && selected < searchResults.length - 1) {
          e.preventDefault();
          setSelected(selected + 1);
        }

        if (e.key === 'Enter' && ~selected) {
          e.preventDefault();
          onSelectUsername(searchResults[selected].username)();
        }
      }
    }
    document.addEventListener('keydown', onKey, false);
    return () => document.removeEventListener('keydown', onKey);
  }, [searchResults, selected, setSelected, onSelectUsername]);

  return (
    <div className='relative'>
      <textarea
        maxLength={ui.commentLength}
        ref={ref}
        value={body}
        rows={expanded ? 3 : 1}
        className={'p-1 w-full border border-slate-100'}
        placeholder={placeholder}
        onChange={e => onChange(e.target.value)}
        onClick={_onClick}
      />
      {showMembershipPrompt && (
        <div className='mt-4 grid grid-cols-3 gap-3'>
          <p className='text-xs sm:text-sm md:text-base text-balance col-span-2'>
            You have reached the guest limit for comments. To continue the conversation, we ask that
            you support our mission with a membership.
          </p>
          <button
            className='text-sm sm:text-base md:text-lg bg-primary text-white px-2 rounded col-span-1'
            onClick={() => navigate('/membership')}
          >
            Join&nbsp;Now
          </button>
        </div>
      )}
      <Popper
        anchorEl={ref.current}
        open={!!searchString}
        placement='bottom-start'
        style={{ zIndex: 40 }}
      >
        <div className='bg-white shadow border rounded'>
          {searchResults?.map((user, i) => (
            <button
              key={user.username}
              onClick={onSelectUsername(user.username)}
              className={classnames(
                'flex text-regular mt-2 cursor-pointer text-sm p-2 font-bold w-full text-left',
                {
                  'bg-gray-300': i === selected,
                },
              )}
            >
              <UserAvatar user={user} className='size-5 rounded-full mr-2' />@{user.username}
            </button>
          ))}
          {searchResults && searchResults.length === 0 && <p className='p-2'>No matches</p>}
        </div>
      </Popper>
    </div>
  );
};

export default CommentBodyTextarea;
