import { useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import useLocalStorageState from 'use-local-storage-state';

import { fetchClient } from 'services/api';
import { userQueryKeys } from './users';

const apiClient = fetchClient();

export const useJwt = () => useLocalStorageState('jwt', { defaultValue: '' });

export const useSignOut = () => {
  const [, setJwt] = useJwt();
  const queryClient = useQueryClient();

  return useCallback(async () => {
    setJwt('');
    return queryClient.resetQueries().then(() => {});
  }, [setJwt, queryClient]);
};

const getToken = async (params = {}) => {
  const client = apiClient;
  const url = '/auth/create-token';
  const { data: response } = await client.post(url, params);
  return response;
};

export const useGetToken = () => {
  return useMutation(({ email, invite, context }) => getToken({ email, invite, context }));
};

async function verifyToken(params) {
  return await apiClient.post('/auth/verify-token', params);
}

export const useVerifyToken = () => {
  const queryClient = useQueryClient();
  const [, setJwt] = useJwt();

  return useMutation(({ token }) => verifyToken({ token: token }).then(res => res.data), {
    onSuccess: data => {
      if (data.jwt) setJwt(data.jwt);

      queryClient.setQueryData(userQueryKeys.me, oldMe => {
        return { ...oldMe, data: data.data };
      });
    },
  });
};
